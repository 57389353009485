import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import cn from 'classnames';

// TODO this seems to be broken in ServicesForm. Just CSS though?
class Select extends Component {
  constructor(props) {
    super();
    this.onChange = this.onChange.bind(this);
    this.state = { touched: false };
  }

  setTouched() {
    this.setState({ touched: true });
  }

  onChange(values) {
    if (this.props.multi) {
      if (values.length <= this.props.limit) {
        this.props.input.onChange(values);
      }
    } else {
      let finalValue = values;
      if (this.props.onChangeModifier) {
        finalValue = this.props.onChangeModifier(values);
      }
      this.props.input.onChange(finalValue);
    }
  }

  render() {
    const { label, input, options, type, multi, placeholder, required, half, clearable,
            valueKey, labelKey, limit, hint, onInputChange, className, labelClassName, searchable,
            defaultValue, meta: { error, warning } } = this.props;
    const { touched } = this.state;

    let value = defaultValue;
    if (defaultValue) {
      value = defaultValue;
    }
    if (input.value) {
      value = input.value;
    }

    return (
      <div
        className={
          cn({
            FormRow: true,
            'FormRow--half': half
          }, className)
        }
      >

        { label &&
          <label
            className={cn({
              FormLabel: true,
              'FormLabel--required': required
            }, labelClassName)}
            htmlFor={input.name}
          >
            {label}
          </label>
        }

        <ReactSelect
          placeholder={placeholder || 'Select'}
          multi={multi || false}
          options={options}
          valueKey={valueKey}
          labelKey={labelKey}
          searchable={searchable}
          clearable={clearable}
          value={value}
          onChange={this.onChange}
          onInputChange={(val) => {this.setTouched(); onInputChange ? onInputChange(val) : null;}}
        />

        {hint &&
          <div className="hint">
            {hint}
          </div>
        }

        {touched && (error || warning) &&
          <div className="ErrorExplanation">
            {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
          </div>
        }
      </div>
    );
  }
}

Select.propTypes = {
  input: PropTypes.object,
  error: PropTypes.string,
  type: PropTypes.string,
  hint: PropTypes.string,
  touched: PropTypes.bool,
  required: PropTypes.bool,
  half: PropTypes.bool,
  warning: PropTypes.string,
  meta: PropTypes.object,
  onInputChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  multi: PropTypes.bool,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  limit: PropTypes.number,
  onChangeModifier: PropTypes.func,
};

Select.defaultProps = {
  limit: 10
};

export default Select;
