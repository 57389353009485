import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, change } from 'redux-form';

// Components
import Input from '../form/Input';
import Select from '../form/Select';
import TextArea from '../form/TextArea';
import Hidden from '../form/Hidden';

// Misc
import * as v from '../../modules/validators';
import styles from './ServicesForm.module.postcss';
import formStyles from '../../../stylesheets/public/components/Forms.module.postcss';

class ServicesForm extends Component {
  constructor(props) {
    super();
    props.change('form_type', props.formType);
  }

  render() {
    const { handleSubmit, submitting, valid, formType } = this.props;

    let group = "Team";
    let members = "employees";
    let organization = "company";

    if (formType === "residential") {
      group = "Community";
      members = "residents";
      organization = "community";
    } else if (formType === "breweries") {
      organization = "location";
    }

    return (
      <form
        onSubmit={handleSubmit}
        className={formStyles.FlexForm + ' ' + styles.ServicesForm}
      >
        <div className="u-grid-row">
          <div className="u-grid-col u-grid-md-half">
            <div className="Fieldset">
              <span className={styles.Required}>* required</span>
              <Field
                label="Name"
                name="name"
                component={Input}
                type="text"
                placeholder="Enter name..."
                validate={[v.required]}
              />
            </div>
            <div className="Fieldset">
              <span className={styles.Required}>* required</span>
              <Field
                label="Email"
                name="email"
                component={Input}
                type="text"
                placeholder="Enter email..."
                validate={[v.required, v.email]}
              />
            </div>
            <div className="Fieldset">
              <Field
                label="City"
                name="city"
                component={Input}
                type="text"
                placeholder="Enter city..."
              />
            </div>
            <div className="Fieldset">
              <Field
                label="Phone number"
                name="phone_number"
                component={Input}
                type="text"
                placeholder="Enter phone number..."
              />
            </div>
          </div>

          <div className="u-grid-col u-grid-md-half">
            {formType === "breweries" &&
              <div className="Fieldset">
                <Field
                  label="Company Name"
                  name="company_name"
                  component={Input}
                  type="text"
                  placeholder="Enter company name..."
                />
              </div>
            }
            {formType !== "breweries" &&
              <div className="Fieldset">
                <Field
                  label={`${group} size`}
                  name="team_size"
                  options={[
                    { label: `10-49 ${members}`, value: '10-49' },
                    { label: `50-249 ${members}`, value: '50-249' },
                    { label: `250-999 ${members}`, value: '250-999' },
                    { label: `1000+ ${members}`, value: '1000+' },
                  ]}
                  valueKey="value"
                  labelKey="label"
                  component={Select}
                  limit={1}
                  placeholder={`Enter ${organization} size...`}
                  type="input"
                />
              </div>
            }
            <div className="Fieldset">
              <Field
                label="Additional notes"
                name="notes"
                component={TextArea}
                placeholder={`Tell us about your ${organization}...`}
              />
            </div>
            <button
              className="Button"
              disabled={!valid || submitting}
              type="submit"
            >
              Send form
            </button>
          </div>
        </div>
      </form>
    );
  }
}

ServicesForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  valid: PropTypes.bool,
  change: PropTypes.func,
};

export default reduxForm({ form: 'services' })(ServicesForm);
