import React from 'react';
import PropTypes from 'prop-types';

const Hidden = (props) => {
  return (
    <input
      {...props}
      type="hidden"
    />
  );
};

Hidden.propTypes = {
  input: PropTypes.object
};

export default Hidden;
